import Headroom from "headroom.js";
import Swiper from "swiper";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from 'vh-check';

const test = vhCheck({
    cssVarName: 'vh-offset'
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true
});

var aboutCarousel = new Swiper(".about__carousel", {
    speed: 300,
    loop: false,
    slidesPerView: 1,
    effect: "fade",
    fadeEffect: {
        crossFade: true
    },
    navigation: {
        prevEl: '.swiper-nav-prev',
        nextEl: '.swiper-nav-next',
    }
});

var partnersCarousel = new Swiper(".partners__carousel", {
    speed: 300,
    loop: true,
    centeredSlides: true,
    autoplay: true,
    spaceBetween: 20,
    slidesPerView: 2.5,
    breakpoints: {
        576: {
            slidesPerView: 3,
            centeredSlides: false,
        },
        768: {
            slidesPerView: 4,
            centeredSlides: false,
        },
        992: {
            slidesPerView: 5,
            centeredSlides: false,
        },
        1200: {
            slidesPerView: 6,
            centeredSlides: false,
        },
        1500: {
            slidesPerView: 7,
            centeredSlides: false,
        },
    }
});

var historyCarousel = new Swiper(".history__carousel", {
    speed: 300,
    loop: false,
    spaceBetween: 9,
    slidesPerView: 1.5,
    navigation: {
        prevEl: '.history-nav-prev',
        nextEl: '.history-nav-next',
    },
    breakpoints: {
        426: {
            slidesPerView: 2,
        },
        576: {
            slidesPerView: 2.5,
        },
        768: {
            slidesPerView: 3,
        },
        992: {
            slidesPerView: 2.5,
        },
        1200: {
            slidesPerView: 3,
        },
    }
});

var presentCarousel = new Swiper(".present__carousel", {
    speed: 300,
    loop: false,
    spaceBetween: 9,
    slidesPerView: 1.5,
    navigation: {
        prevEl: '.present-nav-prev',
        nextEl: '.present-nav-next',
    },
    breakpoints: {
        426: {
            slidesPerView: 2,
        },
        576: {
            slidesPerView: 2.5,
        },
        768: {
            slidesPerView: 3,
        },
        992: {
            slidesPerView: 2.5,
        },
        1200: {
            slidesPerView: 3,
        },
    }
});

//BURGER MENU
const header = document.getElementById("header-wrapper");
const burgerMenu = document.getElementById("burger-menu");
const burgerMenuOpen = document.getElementById("burger-menu-open");
const burgerMenuOverlay = document.getElementById("burger-overlay");
const headroom = new Headroom(header, {
    offset: document.getElementById('header-wrapper').offsetHeight
});
headroom.init();

burgerMenuOpen.addEventListener("click", () => {
    burgerMenu.classList.toggle("open");
    burgerMenuOpen.classList.toggle("open");
    burgerMenuOverlay.classList.toggle("open");
});

burgerMenuOverlay.addEventListener("click", () => {
    burgerMenu.classList.remove("open");
    burgerMenuOpen.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
});

//Scroll up arrow
const scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            document.getElementById("up").classList.add("active");
        } else {
            document.getElementById("up").classList.remove("active");
        }
    } else {
        document.getElementById("up").classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onload = function() {
    scrollUpToggle();
};

window.onscroll = function() {
    scrollUpToggle();
};
